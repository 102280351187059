/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 107번째 로그에서는 트럼프 SNS 계정 차단, AWS Parler 차단, IT 기업의 부동산에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "트럼프 SNS 계정 차단 & AWS Parler 서비스 차단"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bloter.net/archives/514978"
  }, "SNS ‘팔러’, 앱스토어 퇴출…극우 피난처 활용에 ‘단칼’ - 블로터")), "\n"), "\n", React.createElement(_components.h3, null, "IT 기업의 부동산 구매"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.chosun.com/economy/real_estate/2021/01/05/DVQRJI7RO5H6HFSSZMGGBMRSXE/"
  }, "노른자 땅의 텅빈 오피스, 테크기업이 채운다 - 조선일보")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
